import "../assets/css/style.css";
import BannerEl from "../elements/pageSections/banner/BannerEl";
import Layout from "../common/Layout";
import React from "react";
import SloganSection from "../elements/pageSections/banner/SloganSection";
import DigitalSolCards from "../elements/service/DigitalSolCards";
import ItemListings from "../elements/itemlisting/ItemListings";

const BannerData = [
  {
    image: "",
  },
];

const listingData = [
  [
    {
      name: "R2 Studio Q",
      slogan: "All In One Digital Workstation",
      img: "https://placehold.co/300x300",
      alt: "R2 Studio Q CBCT",
    },
    {
      name: "R2i3",
      slogan: "",
      img: "https://placehold.co/300x300",
      alt: "R2i3 IOS",
    },
    {
      name: "MEDIT",
      slogan: "",
      img: "https://placehold.co/300x300",
      alt: "MEDIT IOS",
    },
    {
      name: "Shining 3D",
      slogan: "",
      img: "https://placehold.co/300x300",
      alt: "MEDIT IOS",
    },
  ],
  [
    {
      name: "R2 GATE",
      slogan: "",
      img: "https://placehold.co/300x300",
      alt: "R2 GATE Software",
    },
  ],
  [
    {
      name: "AccuFab-CEL",
      slogan: "",
      img: "https://placehold.co/300x300",
      alt: "AccuFab-CEL",
    },
    {
      name: "AccuFab-L4D/K",
      slogan: "",
      img: "https://placehold.co/300x300",
      alt: "AccuFab-L4D/K",
    },
    {
      name: "Fab Wash",
      slogan: "",
      img: "https://placehold.co/300x300",
      alt: "Fab Wash",
    },
    {
      name: "Fab Cure 2",
      slogan: "",
      img: "https://placehold.co/300x300",
      alt: "Fab Cure 2",
    },
  ],
  [
    {
      name: "Guided Surgical Kits",
      slogan: "",
      img: "https://placehold.co/300x300",
      alt: "Guided Surgical Kits MegaGen",
    },
    {
      name: "R2 Milling Center",
      slogan: "Prosthetic Solutions",
      img: "https://placehold.co/300x300",
      alt: "AccuFab-L4D/K",
    },
  ],
];
const DigitalSolutions = () => {
  const loaded = "https://placehold.co/2560x1080";

  return (
    <>
      <Layout>
        <BannerEl loaded={loaded} bannerData={BannerData} />
        <SloganSection
          slogan={
            "Revolutionizing Dentistry with Comprehensive Digital Solutions"
          }
          product={"Digital Solutions"}
          description={
            "Experience seamless integration from data acquisition to final prosthetic outcomes. Our cutting-edge technology combines precision, efficiency, and advanced AI capabilities to transform your dental practice, delivering exceptional patient care and streamlined workflows."
          }
          alt={"Digital Solutions"}
          secondaryAlt={""}
          visibility={false}
          pdfLink={""}
          image={"https://placehold.co/600x600"}
        />
        <DigitalSolCards />
        {/* add sections for each so would need 4 full sections */}
        {/* make it dynamic component so i can feed it json data and it can auto populate */}
        <ItemListings
          category="Data Acquisition"
          description="From CBCTs to IOS"
          data={listingData[0]}
          bgColor={"#E4F6F8"}
        />
        <ItemListings
          category="Planning & Design"
          description="Something Something"
          data={listingData[1]}
          bgColor={""}
        />
        <ItemListings
          category="Printing"
          description="Something Something"
          data={listingData[2]}
          bgColor={"#E4F6F8"}
        />
        <ItemListings
          category="Surgical Solutions"
          description="Something Something"
          data={listingData[3]}
          bgColor={""}
        />
      </Layout>
    </>
  );
};

export default DigitalSolutions;
