import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import purify from "dompurify";

const TestSectionTitleSplit = ({ slogan, title, classes, description }) => {
  return (
    <div className={`section-title ${classes ? classes : ""}`}>
      <ScrollAnimation
        animateIn="fadeInUp"
        animateOut="fadeInOut"
        animateOnce={true}
      >
        <span
          className="pre-title"
          dangerouslySetInnerHTML={{ __html: purify.sanitize(slogan) }}
        ></span>
      </ScrollAnimation>

      <ScrollAnimation
        animateIn="fadeInUp"
        animateOut="fadeInOut"
        animateOnce={true}
      >
        <h3
          className="title"
          dangerouslySetInnerHTML={{ __html: purify.sanitize(title) }}
          style={{ fontSize: "35px" }}
        ></h3>
      </ScrollAnimation>
      <ScrollAnimation
        animateIn="fadeInUp"
        animateOut="fadeInOut"
        animateOnce={true}
      >
        <span
          className="pre-title"
          dangerouslySetInnerHTML={{ __html: purify.sanitize(description) }}
        ></span>
      </ScrollAnimation>
    </div>
  );
};
export default TestSectionTitleSplit;
