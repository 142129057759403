import React from "react";
import {
  FiFacebook,
  FiYoutube,
  FiInstagram,
  FiLinkedin,
  FiMapPin,
  FiPhone,
} from "react-icons/fi";

const HeaderTopBar = () => {
  return (
    <div className="header-top-bar">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-12 col-md-12 col-12">
            <div className="">
              <div className="">
                <p className="mb--5">HQ</p>
                <p className="mb--5" style={{ textAlign: "" }}>
                  <FiMapPin />
                  <span>
                    <a
                      href="https://www.google.com/maps/place/39-40+Broadway,+Fair+Lawn,+NJ+07410/@40.92645,-74.0958803,15z/data=!4m6!3m5!1s0x89c2fba1219cdcf3:0xb67a88698da6a644!8m2!3d40.9262393!4d-74.0958438!16s%2Fg%2F11bw4c_n8t?entry=ttu"
                      target="_blank"
                      rel="noreferrer"
                      aria-label="megagen america location"
                    >
                      {" "}
                      39-40 Broadway
                      <br />
                      Fair Lawn, NJ 07410
                    </a>
                  </span>
                </p>
              </div>{" "}
              <div className="col-lg-12 col-md-12 col-12">
                <div>
                  <div className="address-content">
                    <p className="mb--5">
                      <FiPhone />
                      <span>
                        <a
                          href="tel:8442885425"
                          aria-label="megagen america phone number"
                        >
                          (844) 288-5425
                        </a>
                      </span>
                    </p>
                  </div>
                  <div className="social-icon-wrapper mb--10">
                    <ul className="social-icon social-default icon-naked">
                      <li>
                        <a
                          href="https://facebook.com/megagenamerica"
                          target="_blank"
                          rel="noreferrer"
                          aria-label="megagen america facebiij"
                        >
                          <FiFacebook />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://instagram.com/megagenamerica"
                          target="_blank"
                          rel="noreferrer"
                          aria-label="megagen america instagram"
                        >
                          <FiInstagram />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.linkedin.com/company/megagenamerica"
                          target="_blank"
                          rel="noreferrer"
                          aria-label="megagen america linkedin"
                        >
                          <FiLinkedin />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.youtube.com/@megagentv"
                          target="_blank"
                          rel="noreferrer"
                          aria-label="megagen america youtube"
                        >
                          <FiYoutube />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="mt--40">
                <p className="mb--5">Logistics</p>
                <p style={{ textAlign: "" }}>
                  <FiMapPin />
                  <span>
                    <a
                      href="https://www.google.com/maps/place/55+Bergenline+Ave,+Westwood,+NJ+07675/data=!4m2!3m1!1s0x89c2ef91466f4995:0x3dc9fa63c396f763?sa=X&ved=1t:242&ictx=111"
                      target="_blank"
                      rel="noreferrer"
                      aria-label="megagen  warehouse location"
                    >
                      {" "}
                      55 Bergenline Ave.
                      <br />
                      Westwood, NJ 07675
                    </a>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderTopBar;
