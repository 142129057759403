import React from "react";

const Blury = () => {
  return (
    <div>
      <div className="gradient-circle"></div>
      <div className="gradient-circle theme-pink"></div>
    </div>
  );
};

export default Blury;
