import Slider from "react-slick";
import { BannerActivation } from "../../../utils/script";
import purify from "dompurify";
import ScrollAnimation from "react-animate-on-scroll";
import { FiFacebook, FiInstagram, FiLinkedin, FiYoutube } from "react-icons/fi";
const BannerEl = ({ loaded, bannerData }) => {
  return (
    <ScrollAnimation
      animateIn="slideInUp"
      animateOut="slideOutDown"
      animateOnce={true}
    >
      <Slider
        className="slider-area slider-style-4 slider-dot slick-dot slick-arrow"
        autoplay={false}
        autoplaySpeed={4000}
        {...BannerActivation}
      >
        {bannerData.map((data, index) => (
          <div key={index} className="single-slide">
            <div
              className="height-750 bg_image"
              data-black-overlay="1"
              style={{
                backgroundImage: `url(${loaded || ""})`,
              }}
            >
              <div className="container">
                <div className="row row--30 align-items-center">
                  <div className="col-12">
                    <div className="inner text-center">
                      <h1
                        className="title"
                        dangerouslySetInnerHTML={{
                          __html: purify.sanitize(data.title),
                        }}
                      ></h1>
                      <p
                        className="description"
                        dangerouslySetInnerHTML={{
                          __html: purify.sanitize(data.description),
                        }}
                      ></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>

      <ul className="social-text-share">
        <li>
          <a
            href="https://instagram.com/megagenamerica"
            target="_blank"
            rel="noreferrer"
            style={{ color: "white" }}
          >
            <FiInstagram />
          </a>
        </li>
        <li>
          <a
            href="https://facebook.com/megagenamerica"
            target="_blank"
            rel="noreferrer"
            style={{ color: "white" }}
          >
            <FiFacebook />
          </a>
        </li>
        <li>
          <a
            href="https://www.youtube.com/@megagentv"
            target="_blank"
            rel="noreferrer"
            style={{ color: "white" }}
          >
            <FiYoutube />
          </a>
        </li>
        <li>
          <a
            href="https://www.linkedin.com/company/megagenamerica"
            target="_blank"
            rel="noreferrer"
            style={{ color: "white" }}
          >
            <FiLinkedin />
          </a>
        </li>
      </ul>
    </ScrollAnimation>
  );
};

export default BannerEl;
