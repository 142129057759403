const GoogleTag = () => {
  return (
    <noscript>
      <iframe
        src="
        https://www.googletagmanager.com/ns.html?id=GTM-T4NJ3LJX"
        height="0"
        width="0"
        style="display:none;visibility:hidden"
      ></iframe>
    </noscript>
  );
};

export default GoogleTag;
