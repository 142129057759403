import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import TestSectionTitle from "../sectionTitle/TestSectionTitle";
import SplitNoButtonSwap from "../split/SplitNoButtonSwap";
import SplitNoButton from "../split/SplitNoButton";
import sinusdatanew from "../../data/splitdata/sinuskitsnew.json";
import SinusCTA from "../calltoaction/SinusCTA";
import SloganSection from "../pageSections/banner/SloganSection";
const SinusTabNewTest = () => {
  return (
    <div>
      <div className="row">
        <div className="col-lg-12">
          <Tabs>
            <div className="row row--30 align-items-center">
              <div className=" mt_md--40 mt_sm--40 ">
                <div className="default-tab style-two">
                  <div
                    className="tab-button-panel"
                    style={{ marginLeft: "50px" }}
                  >
                    <TabList className="tab-button">
                      <Tab>
                        <div className="tab-button">
                          <button
                            style={{ fontSize: "19px", fontWeight: "550" }}
                          >
                            Crestal Approach Kit
                          </button>
                        </div>
                      </Tab>
                      <Tab>
                        <div className="tab-button">
                          <button
                            style={{ fontSize: "19px", fontWeight: "550" }}
                          >
                            Lateral Approach Kit
                          </button>
                        </div>
                      </Tab>
                    </TabList>
                  </div>

                  <div className="tab-content-panel">
                    <TabPanel>
                      <SloganSection
                        slogan={"Innovative Sinus Solutions"}
                        product={"MICA Kit"}
                        description={
                          "MegaGen Implant Crestal Approach (MICA) Kit"
                        }
                        secondaryImg={"https://placehold.co/200x300"}
                        alt={"sinus kits"}
                        secondaryAlt={"sinus kits"}
                        visibility={false}
                        // pdfLink={"/pdf/digital/brochure/sinus_kits.pdf"}
                        image={"https://placehold.co/600x600"}
                      />
                      <TestSectionTitle
                        classes="text-center mb--40"
                        title="MegaGen Implant Crestal Approach Kit"
                        slogan="The advanced solution for seamless and predictable sinus elevation procedures"
                      />

                      <SplitNoButton props={sinusdatanew[0][0]} />
                      <SplitNoButtonSwap props={sinusdatanew[0][1]} />
                      <SplitNoButton props={sinusdatanew[0][2]} />
                      <SplitNoButtonSwap props={sinusdatanew[0][3]} />
                      <SplitNoButton props={sinusdatanew[0][4]} />
                      <SinusCTA />
                      <br />
                      <br />
                      <br />
                    </TabPanel>
                    <TabPanel>
                      <SloganSection
                        slogan={"Innovative Sinus Solutions"}
                        product={"MILA Kit"}
                        description={
                          "MegaGen Implant Lateral Approach (MILA) Kit"
                        }
                        secondaryImg={"https://placehold.co/200x300"}
                        alt={"sinus kits"}
                        secondaryAlt={"sinus kits"}
                        visibility={false}
                        // pdfLink={"/pdf/digital/brochure/sinus_kits.pdf"}
                        image={"https://placehold.co/600x600"}
                      />
                      <TestSectionTitle
                        classes="text-center mb--40"
                        title="MegaGen Implant Lateral Approach Kit"
                        slogan="The advanced solution for seamless and predictable sinus elevation procedures"
                      />
                      <SplitNoButton props={sinusdatanew[1][0]} />
                      <SplitNoButtonSwap props={sinusdatanew[1][1]} />
                      <SplitNoButton props={sinusdatanew[1][2]} />
                      <SplitNoButtonSwap props={sinusdatanew[1][3]} />
                      <SplitNoButton props={sinusdatanew[1][4]} />
                      <SinusCTA />
                      <br />
                      <br />
                      <br />
                    </TabPanel>
                  </div>
                </div>
              </div>
            </div>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default SinusTabNewTest;
