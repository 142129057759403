const SloganSection = ({
  className,
  slogan,
  product,
  description,
  image,
  alt,
  secondaryImg,
  secondaryAlt,
  visibility,
  pdfLink,
}) => {
  return (
    <section className={`mgga-hero-area pt--100 ${className || ""}`}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="mgga-hero-content">
              <span
                style={{
                  fontSize: "25px",
                  fontWeight: 800,
                  color: "#C0C0C0",
                }}
              >
                {slogan}
              </span>
              <h1 className="mgga-title">{product}</h1>
              <p style={{ fontSize: "20px" }}>{description}</p>
              <a
                className={`btn-default ${visibility ? "" : "d-none"}`}
                href={pdfLink}
                target="_blank"
                rel="noreferrer"
                download
              >
                Download Brochure
              </a>
            </div>
          </div>
          <div className="col-lg-6 ">
            <div className="mgga-hero-thumb">
              <div
                className="thumb wow animated fadeInUp"
                data-wow-duration="2000ms"
                data-wow-delay="200ms"
              >
                <img src={image} alt={alt} />
              </div>
              <div>
                <img
                  src={secondaryImg}
                  alt={secondaryAlt}
                  style={{
                    boxShadow: "0px 0px 0px 0px",
                    width: "50%",
                  }}
                  className="thumb-2 wow animated fadeInRight"
                  data-wow-duration="2000ms"
                  data-wow-delay="600ms"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SloganSection;
