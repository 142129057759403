import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import TestSectionTitleSplit from "../sectionTitle/TestSectionTitleSplit";

// Change this to dynamically generate descriptions

const SplitNoButtonSwap = ({ props }) => {
  return (
    <div className="split-area section-gapBottom">
      <div className="wrapper">
        <div className="split-style">
          <div className="split-wrapper">
            <div className="row no-gutters radius-10 align-items-center">
              <div className="col-lg-12 col-xl-6 col-12">
                <div className="split-inner">
                  <ScrollAnimation
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}
                  >
                    <TestSectionTitleSplit
                      classes="text-start mb--30"
                      slogan={props.slogan}
                      title={props.title}
                      description={props.titledesc}
                    />
                  </ScrollAnimation>
                  <ScrollAnimation
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}
                  >
                    <p className="description" style={{ fontSize: "18px" }}>
                      {props.description}
                    </p>
                  </ScrollAnimation>

                  <ScrollAnimation
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}
                  >
                    <ul className="split-list">
                      <li style={{ fontSize: "17px" }}>{props.listItem1}</li>
                      <li style={{ fontSize: "17px" }}>{props.listItem2}</li>
                      <li style={{ fontSize: "17px" }}>{props.listItem3}</li>
                      <li style={{ fontSize: "17px" }}>{props.listItem4}</li>
                      <li style={{ fontSize: "17px" }}>{props.listItem5}</li>
                    </ul>
                  </ScrollAnimation>
                </div>
              </div>
              <div className="col-lg-12 col-xl-6 col-12">
                <div className="thumbnail image-right-content">
                  <img src={props.img} alt={props.alt} loading="lazy" />
                </div>
              </div>
            </div>
          </div>{" "}
        </div>{" "}
      </div>
    </div>
  );
};

export default SplitNoButtonSwap;
