import { useState, useRef } from "react";
import { FiMenu } from "react-icons/fi";
import Logo from "../../elements/logo/Logo";
import Nav from "./Nav";
import MobileMenu from "./MobileMenu";
import DarkmodeBackUp from "./DarkmodeBackup";
import useStickyHeader from "./useStickyHeader";
import logoLight from "../../assets/images/logo/logo_white.png";
import logoDark from "../../assets/images/logo/1200x300-black.png";

const HeaderOne = ({ btnStyle, HeaderSTyle }) => {
  const [ofcanvasShow, setOffcanvasShow] = useState(false);
  const onCanvasHandler = () => {
    setOffcanvasShow((prev) => !prev);
  };
  const ref = useRef();
  const check = true;
  const sticky = useStickyHeader(50);
  const headerClasses = `header-default ${sticky && check ? "sticky" : ""}`;

  return (
    <>
      <header
        ref={ref}
        className={`header header-default ${HeaderSTyle} ${headerClasses}`}
      >
        <div className="container position-relative">
          <div className="row align-items-center row--0">
            <div className="col-lg-3 col-md-6 col-4">
              <Logo image={logoLight} image2={logoDark} />
            </div>
            <div className="col-lg-9 col-md-6 col-8 position-static">
              <div className="header-right">
                <nav className="mainmenu-nav d-none d-xl-block">
                  <Nav />
                </nav>
                {/* <div className="header-btn">
                  <a
                    className={`btn-default ${btnStyle}`}
                    target="_blank"
                    href="/shop"
                    rel="noreferrer"
                  >
                    Shop
                  </a>
                </div> */}
                <div className="mobile-menu-bar ml--5 d-block d-xl-none">
                  <div className="hamberger">
                    <span
                      className="hamberger-button"
                      onClick={onCanvasHandler}
                    >
                      <FiMenu />
                    </span>
                  </div>
                </div>
                <DarkmodeBackUp />
              </div>
            </div>
          </div>
        </div>
      </header>
      <MobileMenu show={ofcanvasShow} onClose={onCanvasHandler} />
    </>
  );
};
export default HeaderOne;
