import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../elements/logo/Logo";
import footerOne from "../../data/footer/footerOne.json";
import ScrollTop from "./ScrollTop";
import logoLight from "../../assets/images/logo/1200x300-black.png";
import logoDark from "../../assets/images/logo/logo_white.png";
import HeaderTopBar from "../header/HeaderTopBar";
import jsonp from "jsonp";

const footerIntemOne = footerOne[1];
const footerIntemTwo = footerOne[2];
const footerIntemThree = footerOne[3];
const footerIntemFour = footerOne[4];

const indexOneLink = footerIntemOne.quicklink;
const indexTwoLink = footerIntemTwo.quicklink;
const indexThreeLink = footerIntemThree.quicklink;

const Result = () => {
  return (
    <p className="success-message">
      You have successfully subscribed to our mailing list!
    </p>
  );
};

const FooterOne = () => {
  const [email, setEmail] = useState("");
  const [result, showresult] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    showresult(true);
    setTimeout(() => {
      showresult(false);
    }, 5000);
    const url =
      "https://megagenamerica.us1.list-manage.com/subscribe/post?u=9fc3756a2fdc723724e5a2a47&amp;id=db61361f0f&amp;f_id=008efbe5f0"; // you can use .env file to replace this
    jsonp(`${url}&EMAIL=${email}`, { param: "c" }, (_, { msg }) => {
      alert(msg);
    });
  };
  return (
    <>
      <footer className="footer footer-style-default">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              {/* Start Single Widget  */}
              <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="footer-widget">
                  <div className="logo">
                    <Logo image={logoDark} image2={logoLight} />
                  </div>
                  <h3 className="text-big">For Lifetime Smiles</h3>
                </div>
                <HeaderTopBar />
              </div>
              {/* End Single Widget  */}

              {/* Start Single Widget  */}
              <div className="col-lg-2 col-md-6 col-sm-6 col-12">
                <div className="footer-widget">
                  <div className="widget-menu-top">
                    <h4
                      className="title"
                      style={{ fontSize: "20px", fontWeight: "550" }}
                    >
                      {footerIntemOne.title}
                    </h4>
                    <div className="inner">
                      <ul className="footer-link link-hover">
                        {indexOneLink.map((data, index) => (
                          <li key={index}>
                            <Link
                              to={`${data.url}`}
                              style={{ fontSize: "15px", fontWeight: "500" }}
                            >
                              {data.text}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>

                  <div className="widget-menu-bottom">
                    <h4
                      className="title"
                      style={{ fontSize: "20px", fontWeight: "550" }}
                    >
                      {footerIntemTwo.title}
                    </h4>
                    <div className="inner">
                      <ul className="footer-link link-hover">
                        {indexTwoLink.map((data, index) => (
                          <li key={index}>
                            <Link
                              to={`${data.url}`}
                              style={{ fontSize: "15px", fontWeight: "500" }}
                            >
                              {data.text}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Single Widget  */}

              {/* Start Single Widget  */}
              <div className="col-lg-2 col-md-6 col-sm-6 col-12">
                <div className="footer-widget">
                  <div className="widget-menu-top">
                    <h4
                      className="title"
                      style={{ fontSize: "20px", fontWeight: "550" }}
                    >
                      {footerIntemThree.title}
                    </h4>
                    <div className="inner">
                      <ul className="footer-link link-hover">
                        {indexThreeLink.map((data, index) => (
                          <li key={index}>
                            <Link
                              to={`${data.url}`}
                              style={{ fontSize: "15px", fontWeight: "500" }}
                            >
                              {data.text}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Single Widget  */}

              {/* Start Single Widget  */}
              <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="footer-widget">
                  <h4
                    className="title"
                    style={{ fontSize: "20px", fontWeight: "550" }}
                  >
                    {footerIntemFour.title}
                  </h4>
                  <div className="inner">
                    <h6
                      className="subtitle"
                      style={{ fontSize: "16px", fontWeight: "500" }}
                    >
                      {footerIntemFour.subtitle}
                    </h6>
                    <form className="newsletter-form" onSubmit={onSubmit}>
                      <div className="form-group">
                        <input
                          type="email"
                          placeholder="Enter Your Email Here"
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <button className="btn-default" type="submit">
                          Subscribe
                        </button>
                      </div>
                      <div className="form-group">
                        {result ? <Result /> : null}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              {/* End Single Widget  */}
            </div>
          </div>
        </div>
      </footer>
      <ScrollTop />
    </>
  );
};

export default FooterOne;
