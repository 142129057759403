import { Helmet } from "react-helmet";

const MetaData = ({ description, title, link, keywords }) => {
  return (
    <Helmet>
      <meta name="robots" content="index, follow" />
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta name="author" content="MegaGen America" />

      <meta name="og:title" content={title} />
      <meta name="og:description" content={description} />
      <meta name="og:type" content="website" />
      <meta name="og:url" content={link} />
      <title>{title}</title>
    </Helmet>
  );
};

export default MetaData;
