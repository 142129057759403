import React from "react";

function DigitalSolCards({ style }) {
  return (
    <>
      <section
        className="mgga-counter-area pt--90 pb--190 mb--120"
        id="counter"
        style={style}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12"></div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div
                className="mgga-single-counter mt-30 wow animated fadeInUp"
                data-wow-duration="2000ms"
                data-wow-delay="200ms"
              >
                <div className="counter-content">
                  <div className="icon">icon </div>
                  <h3 className="title">Data Acquisition</h3>
                  <a className="btn-default btn-small" href="#">
                    View Products
                  </a>{" "}
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div
                className="mgga-single-counter mt-30 item-2 wow animated fadeInUp"
                data-wow-duration="2000ms"
                data-wow-delay="400ms"
              >
                <div className="counter-content">
                  <div className="icon">icon </div>
                  <h3 className="title">Planning & Design</h3>
                  <a className="btn-default btn-small" href="#">
                    View Products
                  </a>{" "}
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div
                className="mgga-single-counter mt-30 item-3 wow animated fadeInUp"
                data-wow-duration="2000ms"
                data-wow-delay="600ms"
              >
                <div className="counter-content">
                  <div className="icon">icon </div>
                  <h3 className="title">
                    Printing
                    <br />
                    &nbsp;
                  </h3>

                  <a className="btn-default btn-small" href="#">
                    View Products
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div
                className="mgga-single-counter mt-30 item-4 wow animated fadeInUp"
                data-wow-duration="2000ms"
                data-wow-delay="800ms"
              >
                <div className="counter-content">
                  <div className="icon">icon </div>
                  <h3 className="title">Surgical Solutions</h3>
                  <a className="btn-default btn-small" href="#">
                    View Products
                  </a>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default DigitalSolCards;
